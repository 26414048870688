.dropdown {
  select{
    font-size: 0.875rem;
    font-weight: 400;
    color: $outer-space;
    width: 14rem;
    padding: 0.75rem 1rem;
    border: 0.5px solid $silver-chalice;
    border-radius: 3px;
    appearance: none;
    -webkit-appearance:none;
    -moz-appearance:none;
  }

  @media only screen and (min-width: 30em) {
    select{
      padding: 0.5rem 1rem;
      font-size: 1rem;
    }
  }
}