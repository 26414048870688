.list-item-banner-height {
  height: 12.5rem;
}

.list-item {
  &:hover .manage-link {
    display: flex;
    background-color: rgba(0, 19, 115, 0.76);
  }

  &:nth-child(3n+1) {
    justify-content: flex-start;
  }

  &:nth-child(3n+2) {
    justify-content: center;
  }

  &:nth-child(3n+0) {
    justify-content: flex-end;
  }
}

@media (min-width: 960px) {
  .list-item-mw-l {
    max-width: 19.25rem;
  }  
}
