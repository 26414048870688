// LARGE
@media only screen and (min-width: 60rem) {
  .mr-3rem-l {
    margin-right: 3rem;
  }
  .pr-1-5rem-l {
    padding-right: 1.5rem;
  }
  .ph-6rem-l {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .minh-17-1875rem-l{
    min-height: 17.1875rem;
  }
  .pt-1-59375rem-l {
    padding-top: 1.59375rem;
  }
  .w-15-l {
    width:  15%;
  }
}

// MEDIUM
@media only screen and (min-width: 30rem) and (max-width: 60rem) {
  .ph-1-5rem-m {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

// NOT SMALL
@media only screen and (min-width: 30rem) {
  .ml-3rem-ns {
    margin-left: 3rem;
  }
  .bt-none-ns {
    border-top: none;
  }
  .mt-16-625rem-ns {
    margin-top: 16.625rem;
  }
  .w-24-625rem-ns{
    width: 24.625rem;
  }
  .mw-26-875rem-ns {
    max-width: 26.875rem;
  }
  .w-15-ns {
    width:  15%;
  }
  .w-55-ns {
    width:  55%;
  }
  .mw-37rem-ns {
    max-width: 37rem;
  }
  .pv1-5rem-ns {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .pb-6rem-ns {
    padding-bottom: 6rem;
  }
  .ph-1-5rem-ns {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .bottom-shadow-ns {
    box-shadow: 0px 6px 8px rgba(161, 161, 161, 0.24);
  }
  .pv-1-5rem-ns {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .minw-1-25rem-ns{
    min-width: 1.25rem;
  }
  .mt-6rem-ns {
    margin-top: 6rem;
  }
  .mb-3rem-ns {
    margin-bottom: 3rem;
  }
}

// SMALL TABLET
@media only screen and (min-width: 30rem) and (max-width: 47.5rem) {
  .w-100-st { width: 100%; }
  .w-80-st { width: 80%; }
  .w-20-st { width: 20%; }
  .order-0-st { order: 0; }
  .order-1-st { order: 1; }
  .order-2-st { order: 2; }
  .order-3-st { order: 3; }
  .pb3-st { padding-bottom: 1rem; }
  .dn-st { display: none; }
  .db-st { display: block; }
  .tr-st { text-align: right; }
  .flex-wrap-st { flex-wrap: wrap; }
}

// SMALL TABLET
@media only screen and (min-width: 30rem) and (max-width: 47.5rem) {
  .dn-st {display: none;}
  .mt0-st { margin-top: 0;}
}

//NOT SMALL TABLET
@media only screen and (min-width: 47.5rem) {
  .flex-nowrap-nst { flex-wrap: nowrap; }
  .w-third-nst { width: 33%; }
  .db-nst { display: block; }
  .dn-nst { display: none; }
  .flex-nst { display: flex; }
  .order-2-nst { order: 2; }
  .w-auto-nst { width: auto; }
  .pv-1-5rem-nst {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .ph-1-5rem-nst {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .pt3-nst {
    padding-top: 1rem;
  }
  .pb0-nst {
    padding-bottom: 0;
  }
  .bn-nst {
    border-bottom-style: none;
    border-bottom-width: 0;
  }
  .mb0-nst {
    margin-bottom: 0;
  }
  .mt2-nst {
    margin-top: 0.5rem;
  }
  .mv0-nst {
    margin-top: 0;
    margin-bottom: 0;
  }
  .order-last-nst {
    order: 99999;
  }
}

@media only screen and (max-width: 30rem)  {
  .dn-s { display: none; }
}
