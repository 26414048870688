.manage-courses-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;

  @media only screen and (min-width: 30rem) {
    flex-direction: row;
  }
}

.manage-course-card-container {
  display: inherit;
  flex-direction: inherit;
  justify-content: inherit;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;

  @media only screen and (min-width: 30rem) and (max-width: 60rem) {
    width: 50%;
  }

  @media only screen and (min-width: 60rem) {
    width: calc(100% / 3);
  }
}

.manage-course-card {
  border: 0.0625rem solid $light-gray-6;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  min-height: 11.75rem;
  justify-content: space-between;

  .course-icon {
    aspect-ratio: 1;
    width: 3rem;
  }
}