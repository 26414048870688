.text-input__disabled {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: border-box;
  width: 100%;
  color: $dark-gray;
  font-weight: 700;
  border-style: solid;
  border-width: 1px;
  padding: 1rem;
  border-radius: 0.25rem;
  background-color: $light-gray-2;
  pointer-events: none;
}

.input__accent--grape {
      accent-color: $inclusion-grape;
}