@import "./variables";

.notification {
  &::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-size: 1.5rem;
    font-weight: 400;
    font-family: "Font Awesome 5 Pro";
    margin-right: 1rem;
  }
  &.success {
    color: $tropical-rain-forest-green;
    &::before {
       content: "\f058";
    }
  }
  &.warning {
    color: $meteor-orange;
    &::before {
       content: "\f071";
    }
  }
  &.error {
    color: $tall-poppy-red;
    &::before {
      content: "\f06a";
   }
  }
}