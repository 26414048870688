.green-hover-btn {
    &:hover {
        color: white;
        background-image: linear-gradient(to right, #175840, #19a974);
    }
}

.red-hover-btn {
    &:hover {
        color: white;
        background-image: linear-gradient(to right, #7c0006, #e7040f);
    }
}

.profile-table {
    @media screen and (max-width: 575px) {
      width: 100%;
      border: none;
    }
}

.pier-action-btn.semi-faded {
  &:disabled, &[disabled] {
    opacity: 0.5;
  }
}