@import "./variables";
@import "./colors";

.inclusion-grape-hover:hover {
  color: $inclusion-grape;
}

.faded-inclusion-grape-disabled[disabled] {
  color: $inclusion-grape;
  background-color: rgba($inclusion-grape, .08);
}

.progress-color {
  color: $meteor-orange;

  &.progress-0 {
    color: $outer-space;
  }
  &.progress-100 {
    color: $tropical-rain-forest-green;
  }
}

.bg-animate {
  transition: background 0.24s ease-in;
}

input:disabled {
  background-color: $light-gray-2;
}

a[disabled] {
  pointer-events: none;
}

.faded-disabled[disabled] {
  opacity: 0.25;
}

.square-btn {
  cursor: pointer;
  border-radius: 0.3125rem;
  border-width: 0.1875rem;
  border-style: solid;
  padding: 1rem 2rem;
  font-weight: 700;
  text-align:center;
  font-size: 1rem;
  transition: background 0.24s ease-in;
  &.small {
    padding: 0.75rem 1.5rem;
    border-width: 0.125rem;
  }
}

@mixin filled-square-btn($bg-color, $text-color, $hover-text-color: $bg-color) {
  background-color: $bg-color;
  color: $text-color;
  border-color: $bg-color;
  &:hover, &:focus {
    background-color: $white;
    color: $hover-text-color;
  }

  &:focus {
    transition: none;
  }
}

@mixin outlined-square-btn($bg-color, $text-color, $hover-text-color: $bg-color) {
  background-color: $bg-color;
  color: $text-color;
  border-color: $text-color;
  &:hover {
    background-color: $text-color;
    color: $hover-text-color;
  }
}

.filled-square-btn.grape-btn {
  @include filled-square-btn($inclusion-grape, white);
}

.outlined-square-btn.grape-btn {
  @include outlined-square-btn(white, $inclusion-grape);
}

.outlined-square-btn.grape-btn:disabled {
  opacity: 0.3;
  cursor: not-allowed;
  pointer-events: none;
}

.filled-square-btn.tall-poppy-red-btn {
  @include filled-square-btn($tall-poppy-red, white);
}

.outlined-square-btn.well-read-btn {
  @include outlined-square-btn(white, map-get($colors, "well-read"));
}
.square-btn.grape-btn.disabled {
  color: white;
  background-color: rgba($inclusion-grape, 0.3);
  pointer-events: none;
  border: none;
}

#skip-nav a {
  position: absolute;
  clip: rect(0px 0px 0px 0px);
  border: 0;
  height: 1px; margin: -1px;
  overflow: hidden;
  padding: 0;
  height: 20px;
  white-space: nowrap;
  color: $white
}

#skip-nav a:focus {
  clip:auto;
  left:0;
  top:150px;
  width:100%;
  height:auto;
  margin:0;
  padding:10px 0;
  border:2px solid $inclusion-grape;
  border-left:none;
  border-right:none;
  text-align: center;
  font-weight:bold;
  color: $inclusion-grape;
}

.pill-link, .pill-link-small {
  @extend .br-pill, .link, .ba;
  color: $dark-gray-5;
  background-color: white;
  &:hover, &.current {
    color: $inclusion-grape;
    border-width: 0.125rem;
  }
}

.pill-link {
  padding: 0.9375rem 1.4375rem;
  &:hover, &.current {
    padding: 0.875rem 1.375rem;
  }
  &.current {
    background-color: rgba($inclusion-grape, .08);
  }
}

.pill-link-small {
  border: none;
  outline: 1px solid #555;
  align-self: center;
  color: #555;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  margin-bottom: 1rem;

  &:hover, &.current {
    outline: 2px solid $inclusion-grape;
    color: $inclusion-grape;
    background-color: #F8EDFF;
  }

  &:hover {
    cursor: pointer;
    
  }

  &.current {
    &:hover {
      cursor: not-allowed;
    }
  }

  @media only screen and (min-width: 30em) {
    margin-bottom: 0;
  }
}

@mixin token-color($text-color, $bg-color) {
  color: $text-color;
  background-color: $bg-color;
  &:not(span):hover {
    color: darken($text-color, 10%);
    background-color: darken($bg-color, 10%);
  }
}

.token-list > span {
  cursor: default;
}

.token-list > a, .token-list > span {
  @include token-color($inclusion-grape, $washed-inclusion-grape);
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.03333125rem;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  margin-left: -0.6rem;
  &:first-child {
    margin-left: 0;
  }
  &:nth-of-type(2n) {
    @include token-color($tropical-rain-forest-green, $skeptic-green);
  }
  &:nth-of-type(3n) {
    @include token-color($red-violet, $washed-pink);
  }
  &.plain-token {
    @include token-color($outer-space, white);
    border: 1px solid $alto;
  }
  &:not(span):hover {
    z-index: $button;
    transform: scale(1.1);
    transition: transform 0.24s ease;
    transition: background-color 0.24 ease;
  }
}

:focus-visible {
  outline: $inclusion-grape auto 1px;
}

.symbol-button {
  border: none;
  background-color: transparent;
}

.symbol-button:before {
  content: "\f06e";
  display: inline-block;
  font-family: "Font Awesome 5 Pro";
  font-size: 1.5rem;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.forgot-btn:hover {
  color: lightgray;
}