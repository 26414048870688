@import './variables';

#product_selection {
  input[type="checkbox"] {
    & + label {
      transition: background-color 0.24s ease-in-out, border-color 0.24s ease-in-out;
      padding: 0.0625rem; //to compensate for thickened border when focused
    }
    &:checked + label {
      background-color: rgba($inclusion-grape, 0.15);
      border: 0.1875rem solid $inclusion-grape; //0.1875rem = 3px for 100% resolution
      padding: 0rem;
    }
    &:hover + label, &:focus + label {
      border: 0.1875rem solid $inclusion-grape;
      padding: 0rem;
    }

    &:disabled + label {
      pointer-events: none;
      background-color: rgba($inclusion-grape, 0.15);
      opacity: 0.5;
    }
  }

  :invalid input[type="submit"] {
    opacity: 0.24;
  }
}

.pricing-label[data-pricing-description="courses"] {
  color: $meteor-orange;
}