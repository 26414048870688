.dark-gray-1 {
  color: #454545;
}

.tracked-semi-tight {
  letter-spacing: 0.008125rem;
}

.tracked-0-03rem {
  letter-spacing: 0.03333125rem;
}

.lh-1-2 {
  line-height: 1.2;
}

.lh-1-4 {
  line-height: 1.4;
}

.lh-1-6{
  line-height: 1.6;
}

.f2-rem {
  font-size: 2rem;
}

.f1-25rem {
  font-size: 1.25rem;
}

.f1-5rem {
  font-size: 1.5rem;
}