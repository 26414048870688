@import "./variables";

@each $name, $hex in $colors {
  .#{$name} {
    color: $hex;
  }
  .bg-#{$name} {
   background-color: $hex;
  }
  .b--#{$name} {
   border-color: $hex;
  }
}

@function colors($name) {
  @return map-get($colors, $name);
}
.course-progress {
  & .bg-completed {
    background-color: colors('tropical-rain-forest-green');
  }
  & .bg-in-progress {
    background-color: colors('meteor-orange');
  }

  & .bg-not-started {
    background-color: colors('tall-poppy-red');
  }

  & .completed {
    color: colors('tropical-rain-forest-green');
  }

  & .in-progress {
    color: colors('meteor-orange');
  }

  & .not-started {
    color: colors('tall-poppy-red');
  }
}

.bg-inclusion-grape-opacity-0-08 {
  background-color: rgba($inclusion-grape, .08);
}

.inclusion-grape-hover:hover {
  color: $inclusion-grape;
}

.light-inclusion-grape-hover:hover {
  color: rgba($inclusion-grape, 0.5);
}

.b--inclusion-grape-hover {
  border-color: $inclusion-grape;
}