$fa-font-path: "~@fortawesome/fontawesome-pro/webfonts";
@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/brands";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~react-table/react-table";

@import "../stylesheets/custom_tachyons.scss";
@import "~tachyons-sass/tachyons";

@import "../stylesheets/user-styles.scss";
@import "../stylesheets/header-styles.scss";

@import "../stylesheets/react-table-overrides.scss";
@import "../stylesheets/media_query_tachyons";
@import "../stylesheets/notifications.scss";
@import "../stylesheets/typography.scss";
@import "../stylesheets/courses.scss";
@import "../stylesheets/modal.scss";
@import "../stylesheets/colors.scss";
@import "../stylesheets/variables.scss";
@import "../stylesheets/buttons.scss";
@import "../stylesheets/tooltips.scss";
@import "../stylesheets/product_selection.scss";
@import "../stylesheets/users.scss";
@import "../stylesheets/forms";
@import "../stylesheets/animations";
@import "../stylesheets/manage_course_card.scss";
@import "../stylesheets/tristate_checkbox.scss";
@import '../stylesheets/archived_seats';
@import '../stylesheets/table';
@import '../stylesheets/dropdown';

html {
  scroll-behavior: auto;
}

.pier-font, button, input, optgroup, select, textarea {
  font-family: "Montserrat", "Open Sans", "Helvetica", "Arial", sans-serif;
}

.f7 {
  font-size: .75rem;
}

.min-vh-50 {
  min-height: 50vh;
}

.active {
  display:block;
}
.active.flex {
  display: flex;
}
.styles_overlay__CLSq- {
  background: rgba(0, 0, 0, 0.10);
}

.shadow {
  -webkit-box-shadow: 0px 3px 36px 0px rgba(161,161,161,0.5);
  -moz-box-shadow: 0px 3px 36px 0px rgba(161,161,161,0.5);
  box-shadow: 0px 3px 36px 0px rgba(161,161,161,0.5);
}

@media (min-width: 960px) {
  .shadow-l {
    -webkit-box-shadow: 0px 3px 36px 0px rgba(161,161,161,0.5);
    -moz-box-shadow: 0px 3px 36px 0px rgba(161,161,161,0.5);
    box-shadow: 0px 3px 36px 0px rgba(161,161,161,0.5);
  }
}

.h2-5-ns{
  @media only screen and (min-width: 60rem) {
    height: 3rem !important;
  }
}

.h0{
  height: 0px;
}

.w0{
  width: 0px;
}

@media screen and (max-width: 30rem){
  #rc-imageselect, .g-recaptcha {
    transform:scale(0.77);
  }
}