@import "./variables";

.pointer-events-none {
  pointer-events: none;
}

.w-pixel {
  width: 1px;
}

.w-15 {
  width:  15%;
}

.w-3rem {
  width: 3rem;
}

.mw-62rem {
  max-width: 62rem;
}

.min-width-0-625rem {
  min-width: 0.625rem;
}

.mb1-5rem {
  margin-bottom: 1.5rem;
}

.mt1-5rem {
  margin-top: 1.5rem;
}

.mt3rem {
  margin-top: 3rem;
}

.mv1-5rem {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mr1-5rem {
  margin-right: 1.5rem;
}

.w-1-5rem {
  width: 1.5rem;
}

.mw-10rem {
  max-width: 10rem;
}

.mt-10rem {
  margin-top: 10rem;
}

.mr-neg-offset {
  margin-right: -0.135rem;
}

.mt-3rem {
  margin-top: 3rem;
}

.mb-3rem {
  margin-bottom: 3rem;
}

.mv-3rem {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.lh-1-6 {
  line-height: 1.6;
}

.bw-0-06rem {
  border-width: 0.0625rem;
}

.bw-0-03rem {
  border-width: 0.03125rem;
}

.br-0-18rem {
  border-radius: 0.1875rem;
}

.w-14rem {
  width: 14rem;
}

.soft-shadow {
  box-shadow: -1px 6px 9px rgba(0, 0, 0, 0.09);
}

.bottom-shadow {
  box-shadow: 0px 6px 8px rgba(161, 161, 161, 0.24);
}

.w-11-68rem {
  width: 11.6875rem;
}

.mw-25rem {
  max-width: 25rem;
}

.mw-26rem {
  max-width: 26rem;
}

.h2-5rem {
  height: 2.5rem;
}

.mt-3rem {
  margin-top: 3rem;
}

.mt-3rem {
  margin-top: 3rem;
}

.pa1-5rem {
  padding: 1.5rem;
}

.pv1-5rem {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pv0-75rem{
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.pt1-5rem {
  padding-top: 1.5rem;
}

.bw-0-1875rem{
  border-width: 0.1875rem;
}

.bw-0-1875rem-hover:hover{
  border-width: 0.1875rem;
}

.bb-none {
  border-bottom: none;
}

.b--french-gray {
  border-color: $french-gray;
}
.z-behind {
  z-index: $beneath;
}

.b--silver-chalice {
  border-color: $silver-chalice;
}

.mw-6-25rem {
  max-width: 6.25rem;
}

.mw-6-375rem {
  max-width: 6.375rem;
}

.mw-28-5rem {
  max-width: 28.5rem;
}

.mw-24-625rem {
  max-width: 24.625rem;
}

.ph0-75rem {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.mw-33-875rem {
  max-width: 33.875rem;
}

.ph1-5rem {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.ph1-375rem {
  padding-left: 1.375rem;
  padding-right: 1.375rem;
}

.ph1-375rem-hover:hover {
  padding-left: 1.375rem;
  padding-right: 1.375rem;
}

.pv0-875rem {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

.pb0-875rem {
  padding-bottom: 0.875rem;
}

.pv0-875rem-hover:hover {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

.min-w-1-5rem {
  min-width: 1.5rem;
}

.beneath-parent {
  top: 100%
}

.mt-6rem {
  margin-top: 6rem;
}

.pb-6rem {
  padding-bottom: 6rem;
}

.pb-1-5rem {
  padding-bottom: 1.5rem;
}

.pb-3rem {
  padding-bottom: 3rem;
}

.pb-3-75rem {
  padding-bottom: 3.75rem;
}

.w-21-4375rem {
  width: 21.4375rem;
}

.mt-1-125rem {
  margin-top: 1.125rem;
}

.mt-1-5rem{
  margin-top: 1.5rem;
}

.break-word {
  word-break: break-all;
}

.width-3-5rem {
  width: 3.5rem;
}

.width-14rem {
  width: 14rem;
}

.mw-78rem {
  max-width: 78rem;
}

.mw-65rem {
  max-width: 65rem;
}

.ph-1-5rem {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.minw-1-25rem{
  min-width: 1.25rem;
}

.bn-last-child:last-child {
  border-style: none;
  border-width: 0;
}

.pt-2-375rem {
  padding-top: 2.375rem;
}