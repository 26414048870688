.tristate-checkbox-wrapper {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
}

.tristate-checkbox {
  position: relative;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  vertical-align: middle;
  border-radius: 0.1875rem;

  &:focus-within {
    box-shadow: 0 0 0 0.1875rem $inclusion-grape;
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .checkbox-icon {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: "Font Awesome 5 Pro";
      font-weight: 400;
      font-size: 1rem;
      line-height: 1;
      content: "\f0c8";
      color: black;
    }

    &.checked {
      &:after {
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
        content: "\f14a";
        color: black;
      }

      &.grape:after {
        color: $inclusion-grape;
      }
    }

    &.indeterminate {
      &:after {
        font-family: "Font Awesome 5 Pro";
        content: "\f146";
        font-weight: 900;
        color: black;
      }

      &.grape:after {
        color: $inclusion-grape;
      }
    }
  }
}