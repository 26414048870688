$colors: (
  emperors-gray: #555555,
  purple: #5C4ACD,
  dark-gray: #505050,
  dark-gray-1: #17142A,
  dark-gray-3: #4F5564,
  dark-gray-4: #697685,
  dark-gray-5: #555555,
  light-gray-1:#9E9E9E,
  light-gray-2:#C9CBD0,
  light-gray-3:#8A8A8A,
  light-gray-4:#FAFAFA,
  light-gray-5:#AEAEAE,
  cv-light-gray-1: #8C9CAE,
  cv-light-gray-2: #DCDFE6,
  cv-light-gray-3: #E9EDF1,
  cv-light-gray-4: #F5F6F8,
  blue: #0026CA,
  dark-blue:#000070,
  dark-blue-2: #17142A,
  washed-blue: #EBEEFB,
  off-white: #FDFDFD,
  off-white-2:#F8F8F8,
  light-blue: #F6F6FF,
  dark-orange: #F94B03,
  meteor-orange: #D27A13,
  navy: #0C0A3E,
  mercury-gray: #E4E4E4,
  concrete-gray: #F2F2F2,
  french-gray: #C4C4C4,
  jade-green: #00B280,
  tropical-rain-forest-green: #008250,
  skeptic-green: #D6EBE3,
  tall-poppy-red: #C02425,
  silver: #C4C4C4,
  gallery: #EEEEEE,
  alto: #D1D1D1,
  outer-space: #323941,
  alabaster-gray: #f9f9f9,
  silver-chalice: #AAAAAA,
  red-violet: #C40D7D,
  washed-pink: #F6D8EA,
  washed-red: rgba(192, 36, 37, 0.08),
  inclusion-grape: #6B1D93,
  well-read: #B0342E,
);

$purple: #5C4ACD;

$dark-gray: #505050;
$dark-gray-1: #17142A;
$dark-gray-3: #4F5564;
$dark-gray-4: #697685;
$dark-gray-5: #555555;

$light-gray-1:#9E9E9E;
$light-gray-2:#C9CBD0;
$light-gray-3:#8A8A8A;
$light-gray-4:#FAFAFA;
$light-gray-5:#AEAEAE;
$light-gray-6:#CFD0D5;

//course-viewer light grays
$cv-light-gray-1: #8C9CAE;
$cv-light-gray-2: #DCDFE6;
$cv-light-gray-3: #E9EDF1;
$cv-light-gray-4: #F5F6F8;

$blue: #0026CA;
$dark-blue:#000070;
$dark-blue-2: #17142A;
$washed-blue: #EBEEFB;
$off-white: #FDFDFD;
$off-white-2:#F8F8F8;
$light-blue: #F6F6FF;
$dark-orange: #F94B03;
$meteor-orange: #D27A13;
$navy: #0C0A3E;
$mercury-gray: #E4E4E4;
$concrete-gray: #F2F2F2;
$french-gray: #C4C4C4;
$jade-green: #00B280;
$tropical-rain-forest-green: #008250;
$skeptic-green: #D6EBE3;
$tall-poppy-red: #C02425;
$silver: #C4C4C4;
$gallery: #EEEEEE;
$alto: #D1D1D1;
$outer-space: #323941;
$alabaster-gray: #f9f9f9;
$silver-chalice: #AAAAAA;
$red-violet: #C40D7D;
$washed-pink: #F6D8EA;
$washed-red: rgba(192, 36, 37, 0.08);
$inclusion-grape: #6B1D93;
$washed-inclusion-grape: #E7DBEE;

// z-index's
$beneath: -1;
$button: 100;
$tooltip: 200;
$toolbar: 300;
$raised: 400;
$modal: 500;

