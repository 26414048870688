$tooltip-pointer-height: 18px;
$tooltip-pointer-margin: 2px;

.tooltip-container {
  position: relative;
  &:hover .tooltip {
    display: block;
  }
}

.tooltip {
  border-radius: 3px;
  position: absolute;
  display: none;
  bottom: calc(100% + #{$tooltip-pointer-height} + #{$tooltip-pointer-margin});
  left: 50%;
  transform: translateX(-50%);
  transform-style: preserve-3d;
  &::after {
    content: "";
    z-index: $beneath;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%) translateZ(-1px);
    border-top-style: solid;
    border-top-width: $tooltip-pointer-height;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
}