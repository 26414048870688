.table-filters {
  padding: 2.5rem 2.375rem 2.5rem 1.5rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  background-color: $alabaster-gray;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;

  @media only screen and (min-width: 30em) {
    align-items: center;
    padding: 2.25rem 2.375rem 2.25rem 1.5rem; 
  }
}

.table-filters__filter-label {
  padding-right: 0.5rem;
  color: #555;
  font-size: 0.75rem;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 160%;
  width: 100%;

  @media only screen and (min-width: 30em) {
    width: fit-content;
  }
}

.table-filters__filter-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 3rem;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-right: 0;
  }

  .dropdown {
    select {
      width: fit-content;
      padding-right: 2rem;
      font-size: 0.75rem;
    }
  }

  @media only screen and (min-width: 60em) {
    margin-bottom: 0;
  }
}

.table-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2rem;
  height: 2.5rem;
  outline: 1px solid #ccc;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  font-weight: 500;
  max-width: 15.625rem;
  width: 100%;
  background-color: white;
}