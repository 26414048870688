.group-management {

  .ReactTable .rt-tbody {
    font-size: .8rem;
  }
  .ReactTable .rt-thead {
    font-size: 1rem;
    input {
      font-size: .8rem;
    }
  }
  .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td, .ReactTable .rt-tbody .rt-td {
    border-right: none;
    text-align: left;
    font-weight: 500;
    color: #ccc;
  }

  .ReactTable {
    .rt-thead {
      padding-top: .15rem;
      padding-bottom: .15rem;
    }
    &.inset-shadow {
      box-shadow: inset 0 0 10px #ccc;
    }
    border-radius: 4px;
    background: white;
    .rt-tbody {
      .rt-td {
        border: none;
        // background: white;
        display: flex;
        font-weight: 500;
        color: #ccc;
        align-items: center;
        flex-wrap: wrap;
        input {
          background: #f8f8f8;
          width: 100%;
        }
      }
    }
  }

  .ReactTable .rt-tbody .rt-tr-group {
    border: none;
  }
  // row
  .ReactTable .rt-tr {
    align-items: center;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    &.-odd {
      background: #f9f9f9;
    }
    &.-even {
      background: white;
    }
  }

  .ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc, .ReactTable .rt-thead .rt-th.-sort-desc {
    box-shadow: none;
  }

  .ReactTable .rt-thead.-header {
    box-shadow: none;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
  }

  .ReactTable {
    .-pagination {
      font-size: .8rem;
      box-shadow: none;
      border-top: 1px solid rgba(0, 0, 0, .1);
      justify-content: center;
      padding: 2rem;
      .-previous, .-next {
        flex: 0;
      }
      .-center {
        flex-wrap: nowrap;
        flex: 0;
      }
      .-pageInfo, .-pageSizeOptions {
        margin-top: 0;
        margin-bottom: 0;
      }
      input, select, .-btn {
        height: 2rem;
        border: 1px solid #aaa;
        background: #FAFAFA;
      }
      .-btn:active, .-btn:focus {
        background: #57E3C4;
        border: 1px solid darken(#57E3C4, 20%)
      }
    }
  }
}
