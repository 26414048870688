.user_row:nth-child(even) {
  background-color: $alabaster-gray;
}

.faIcon:after {
  position: absolute;
  right: 1rem;
  top: 25%;
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  margin-left: 1rem;
  pointer-events: none;
}