.shadow-left {
  box-shadow: -5px 0px 50px 0px rgba(0,0,0,0.5);
 }

.slim_header_height {
  height: 4.25rem;
  @media only screen and (min-width: 60rem) {
    height: 5.25rem;
  }
}

.tall_header_height {
  height: 7.9375rem;
  @media only screen and (min-width: 60rem) {
    height: 9.5rem;
  }
}

.banner--testing-env {
  background-color: orange;
  text-align: center;
  z-index: 99999;
  position: fixed;
  width: 100%;
}