.Modal {
  position: relative;
  cursor: auto;
  z-index: $modal;
  background-color: white;
  width: calc(100% - 2rem);
  border-radius: 0.1875rem;
  padding: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  left: 0;
  top: 45%;
  transition: all 0.24s ease-out;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (min-width: 30rem) {
  .Modal {
    width: 30rem;
    left: calc(50% - 13rem);
    padding: 2rem;
    margin-left: 0;
  }
}